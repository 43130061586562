import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import Cache from 'ion-cache'

export const FETCH_EDITOR_CHOICE_ARTICLES = 'api/FETCH_EDITOR_CHOICE_ARTICLES'
export const FETCH_EDITOR_CHOICE_ARTICLES_SUCCESS = 'api/FETCH_EDITOR_CHOICE_ARTICLES_SUCCESS'
export const FETCH_EDITOR_CHOICE_ARTICLES_ERROR = 'api/FETCH_EDITOR_CHOICE_ARTICLES_ERROR'

const SERVER_URL = typeof window !== 'undefined' ? '/data/editorchoice/' : process.env.RAZZLE_EDITOR_CHOICE
const cache = new Cache()

async function fetchArticlesAPI () {
  const key = 'editorchoice'
  const result = await cache.get(key)
  if (result) {
    return result
  }
  return axios.get(SERVER_URL)
    .then(response => {
      return cache.set(key, response.data)
    })
    .catch(err => {
      // console.error(err)
      throw err
    })
}

function * fetchEditorsChoiceArticlesSaga () {
  try {
    const res = yield call(fetchArticlesAPI)
    let payload = {}
    payload = res.contents

    yield put({ type: FETCH_EDITOR_CHOICE_ARTICLES_SUCCESS, payload })
  } catch (e) {
    yield put({ type: FETCH_EDITOR_CHOICE_ARTICLES_ERROR, payload: e.message })
  }
}

export function * watchfetchEditorsChoiceArticles () {
  yield takeLatest(FETCH_EDITOR_CHOICE_ARTICLES, fetchEditorsChoiceArticlesSaga)
}

// Saga actions
export const fetchEditorsChoiceArticles = (section) => ({ type: FETCH_EDITOR_CHOICE_ARTICLES, section })

const initialState = {
  didInvalidate: false,
  isFetching: false,
  hasFetched: false,
  hasError: false,
  error: null,
  articles: {} // do not mutate these
}

export const Reducer = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case FETCH_EDITOR_CHOICE_ARTICLES:
      return Object.assign({}, state, {
        didInvalidate: false,
        isFetching: true,
        hasFetched: false,
        hasError: false,
        error: null
      })
    case FETCH_EDITOR_CHOICE_ARTICLES_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        error: payload,
        hasFetched: true,
        isFetching: false,
        didInvalidate: true
      })
    case FETCH_EDITOR_CHOICE_ARTICLES_SUCCESS:
      return Object.assign({}, state, {
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        articles: payload
      })
    default:
      return state
  }
}
