
import { takeLatest, takeEvery, put, select, delay } from 'redux-saga/effects'
import { clearSlots, performSingleRequest } from 'ion-ads'
import { NAVIGATE_TO } from 'ion-navchevron'
import { getAllArticles, FETCH_ARTICLES_SUCCESS } from '../store/articles'

export const HIDE_MENU = 'HIDE_MENU'
export const SHOW_MENU = 'SHOW_MENU'
export const TOGGLE_MENU = 'TOGGLE_MENU'

export const HIDE_SEARCH = 'HIDE_SEARCH'
export const SHOW_SEARCH = 'SHOW_SEARCH'
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH'

export const EXPERIMENT_WIN = 'EXPERIMENT_WIN'
export const PAGE_VIEW = 'PAGE_VIEW'
export const UPDATE_CONNECTIVITY = 'UPDATE_CONNECTIVITY'

export const SELECT_MOST_READ_TAB = 'SELECT_MOST_READ_TAB'
export const UPDATE_WINGBANNER_LEFT_ACTIVE = 'UPDATE_WINGBANNER_LEFT_ACTIVE'
export const UPDATE_WINGBANNER_RIGHT_ACTIVE = 'UPDATE_WINGBANNER_RIGHT_ACTIVE'
export const AD_SLOT_REGISTERED = 'AD_SLOT_REGISTERED'

export const PREFETCH = 'PREFETCH'

export const hideMenu = () => ({ type: HIDE_MENU, payload: false })
export const showMenu = () => ({ type: SHOW_MENU, payload: true })
export const toggleMenu = () => ({ type: TOGGLE_MENU })

export const hideSearch = () => ({ type: HIDE_SEARCH, payload: false })
export const showSearch = () => ({ type: SHOW_SEARCH, payload: true })
export const toggleSearch = () => ({ type: TOGGLE_SEARCH })

export const updateConnectivity = (isConnected) => ({ type: UPDATE_CONNECTIVITY, payload: isConnected })

export const adSlotRegistered = (slotId) => ({ type: AD_SLOT_REGISTERED, slotId })

export const experimentWin = (experiment, variant) => ({ type: EXPERIMENT_WIN, payload: experiment, variant })
export const pageView = (path) => ({ type: PAGE_VIEW, payload: path })
export const isConnected = state => state.app.isConnected

export const onSlotRenderEndedLeft = (e) => ({ type: UPDATE_WINGBANNER_LEFT_ACTIVE, payload: e })
export const onSlotRenderEndedRight = (e) => ({ type: UPDATE_WINGBANNER_RIGHT_ACTIVE, payload: e })

export const setMostReadTab = (mostReadTab) => ({ type: SELECT_MOST_READ_TAB, payload: mostReadTab })

export const Prefetch = () => ({ type: PREFETCH })

function * prefetchSaga () {
  const articles = yield select(getAllArticles)
  for (const section in articles) {
    yield put({ type: FETCH_ARTICLES_SUCCESS, payload: articles[section], section })
  }
}

export function * watchPrecache () {
  yield takeEvery(PREFETCH, prefetchSaga)
}

function * hideMenuSaga () {
  yield put({ type: HIDE_MENU, payload: false })
}

function * transitionTo (path) {
  // When navigating to a new location, destroy all the current slots
  clearSlots()

  yield
}

function * showLastSlotRegistered ({ slotId }) {
  // yield delay(2000) - seems to give stable results, but is very long
  // Make it 100ms and YOLO
  yield delay(100)
  performSingleRequest(slotId)
}

export function * watchNavChanges () {
  yield takeLatest(PAGE_VIEW, transitionTo)
  yield takeLatest(NAVIGATE_TO, hideMenuSaga)
  yield takeLatest(AD_SLOT_REGISTERED, showLastSlotRegistered)
}

const defaultState = {
  showMenu: false,
  showSearch: false,
  isConnected: true,
  path: '',
  mostReadTab: 0
}

export const Reducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case UPDATE_WINGBANNER_LEFT_ACTIVE:
      return { ...state, ...{ wingbannerLeftActive: !payload.isEmpty } }
    case UPDATE_WINGBANNER_RIGHT_ACTIVE:
      return { ...state, ...{ wingbannerRightActive: !payload.isEmpty } }
    case UPDATE_CONNECTIVITY:
      return { ...state, ...{ isConnected: payload } }
    case EXPERIMENT_WIN:
      return { ...state, ...{ experiment: payload.experiment, variant: payload.variant } }
    case PAGE_VIEW:
      return { ...state, ...{ path: payload } }
    case HIDE_SEARCH:
    case SHOW_SEARCH:
      return { ...state, ...{ showSearch: payload } }
    case TOGGLE_SEARCH:
      return { ...state, ...{ showSearch: !state.showSearch } }
    case SHOW_MENU:
    case HIDE_MENU:
      return { ...state, ...{ showMenu: payload } }
    case TOGGLE_MENU:
      return { ...state, ...{ showMenu: !state.showMenu } }
    case SELECT_MOST_READ_TAB:
      return { ...state, ...{ mostReadTab: payload } }
    default:
      return state
  }
}
