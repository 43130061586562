import React from 'react'
import { NavLink } from 'ion-navchevron'
import 'semantic-ui-css/components/menu.min.css'
import { Facebook, Twitter, Instagram, Close, Search } from 'ion-icon'

const NavMenu = ({ toggleMenu }) => (
  // eslint-disable-next-line
  <nav role='navigation'>
    <button className='close-btn' onClick={toggleMenu}><Close width='20' height='20' /></button>
    <ul className='menu-social'>
      <li>
        <a href={process.env.RAZZLE_SITE_FACEBOOK} target='_blank' rel='noopener noreferrer' title='Like Daily Voice on Facebook'>
          <Facebook width='30' height='30' />
        </a>
      </li>
      <li>
        <a href={process.env.RAZZLE_SITE_TWITTER} target='_blank' rel='noopener noreferrer' title='Follow Daily Voice on Twitter'>
          <Twitter width='30' height='30' fill='white' />
        </a>
      </li>
      <li>
        <a href={process.env.RAZZLE_SITE_INSTAGRAM} target='_blank' rel='noopener noreferrer' title='Follow GQ on Instagram'>
          <Instagram width='30' height='30' />
        </a>
      </li>
    </ul>
    <div className='mobile-nav-search'>
      <form action='/search' method='get'>
        <input type='search' placeholder='Search Daily Voice' name='q' id='q' />
        <button><Search width='20' height='20' /></button>
      </form>
    </div>
    <ul className='main-nav'>
      <li>
        <NavLink to='/news'>News</NavLink>
        <ul className='sub-nav'>
          <li>
            <NavLink to='/news/western-cape'>Western Cape</NavLink>
          </li>
          <li>
            <NavLink to='/news/national'>National</NavLink>
          </li>
          <li>
            <NavLink to='/news/international'>International</NavLink>
          </li>
          <li>
            <NavLink to='/news/business'>Business</NavLink>
          </li>
          <li>
            <NavLink to='/news/politics'>Politics</NavLink>
          </li>
        </ul>
      </li>
      <li>
        <NavLink to='/sport'>Sport</NavLink>
        <ul className='sub-nav'>
          <li>
            <NavLink to='/sport/highlights'>Highlights</NavLink>
          </li>
          <li>
            <NavLink to='/sport/cricket'>Cricket</NavLink>
          </li>
          <li>
            <NavLink to='/sport/rugby'>Rugby</NavLink>
          </li>
          <li>
            <NavLink to='/sport/soccer'>Soccer</NavLink>
          </li>
        </ul>
      </li>
      <li>
        <NavLink to='/lifestyle-entertainment'>Lifestyle &amp; Entertainment</NavLink>
        <ul className='sub-nav'>
          <li>
            <NavLink to='/lifestyle-entertainment/celebrity'>Celebrity</NavLink>
          </li>
          <li>
            <NavLink to='/lifestyle-entertainment/entertainment'>Entertainment</NavLink>
          </li>
          <li>
            <NavLink to='/lifestyle-entertainment/cape-spy'>Cape Spy</NavLink>
          </li>
          <li>
            <NavLink to='/lifestyle-entertainment/cinema'>Cinema</NavLink>
          </li>
          <li>
            <NavLink to='/lifestyle-entertainment/gadget-games'>Gadget &amp; Games</NavLink>
          </li>
          <li>
            <NavLink to='/lifestyle-entertainment/tonight'>Tonight</NavLink>
          </li>
          <li>
            <NavLink to='/lifestyle-entertainment/recipes'>Recipes</NavLink>
          </li>
          <li>
            <NavLink to='/lifestyle-entertainment/lifestyle'>Lifestyle</NavLink>
          </li>
          <li>
            <NavLink to='/lifestyle-entertainment/travel'>Travel</NavLink>
          </li>
          <li>
            <NavLink to='/lifestyle-entertainment/parenting'>Parenting</NavLink>
          </li>
          <li>
            <NavLink to='/lifestyle-entertainment/health-beauty'>Health &amp; Beauty</NavLink>
          </li>
          <li>
            <NavLink to='/lifestyle-entertainment/horoscope'>Horoscope</NavLink>
          </li>
          <li>
            <NavLink to='/lifestyle-entertainment/photo-of-the-day'>Photo of the Day</NavLink>
          </li>
        </ul>
      </li>
      <li>
        <NavLink to='/multimedia'>Multimedia</NavLink>
      </li>
      <li>
        <NavLink to='/opinion'>Opinion</NavLink>
        <ul className='sub-nav'>
          <li>
            <NavLink to='/opinion/sport'>Sport</NavLink>
            <ul className='sub-nav'>
              <li>
                <NavLink to='/opinion/sport/dudley-carstens'>Dudley Carstens</NavLink>
              </li>
              <li>
                <NavLink to='/opinion/sport/matthew-marcus'>Matthew Marcus</NavLink>
              </li>
              <li>
                <NavLink to='/opinion/sport/nick-feinberg'>Nick Feinberg</NavLink>
              </li>
              <li>
                <NavLink to='/opinion/sport/julia-stuart'>Julia Stuart</NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to='/opinion/motoring'>Motoring</NavLink>
            <ul className='sub-nav'>
              <li>
                <NavLink to='/opinion/motoring/bobby-nitro'>Bobby Nitro</NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to='/opinion/current-affairs'>Current Affairs</NavLink>
            <ul className='sub-nav'>
              <li>
                <NavLink to='/opinion/current-affairs/bobby-brown'>Bobby Brown</NavLink>
              </li>
              <li>
                <NavLink to='/opinion/current-affairs/munier-grootbek'>Munier Grootbek</NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to='/opinion/lifestyle'>Lifestyle</NavLink>
            <ul className='sub-nav'>
              <li>
                <NavLink to='/opinion/lifestyle/cooksisters'>Cooksisters</NavLink>
              </li>
              <li>
                <NavLink to='/opinion/lifestyle/waseef-piekaan'>Waseef Piekaan</NavLink>
              </li>
              <li>
                <NavLink to='/opinion/lifestyle/auntie-pearl'>Auntie Pearl</NavLink>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <NavLink to='/competitions'>Competitions</NavLink>
      </li>
      <li>
        <NavLink to='/weather'>Weather</NavLink>
      </li>
      <li>
        <NavLink to='/contact-us'>Contact</NavLink>
      </li>
    </ul>
  </nav>)

export default NavMenu
