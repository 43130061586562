import App from './app/components/App'
import { BrowserRouter } from 'react-router-dom'
import React from 'react'
import { loadableReady } from '@loadable/component'
import { hydrateRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import createStore from './store/createStore'
import rootSaga from './store/sagas'
import { updateConnectivity } from './store/app'

function getVisitorId () {
  return document.cookie
    .split('; ')
    .find(row => row.startsWith('visitorId='))
    .split('=')[1]
}
function getToken () {
  if (window.localStorage) {
    return window.localStorage.getItem('token')
  }
}
const store = createStore({
  ...window.__PRELOADED_STATE__,
  ...{
    user: {
      userToken: getToken(),
      visitorId: getVisitorId()
    }
  }
})

store.runSaga(rootSaga)

window.addEventListener('offline', () => {
  store.dispatch(updateConnectivity(false))
})

window.addEventListener('online', () => {
  store.dispatch(updateConnectivity(true))
})

loadableReady(() => {
  const container = document.getElementById('root')
  hydrateRoot(container,
    <Provider store={store}>
      <BrowserRouter>
        <App withReadCount />
      </BrowserRouter>
    </Provider>
  )
})

if (module.hot) {
  module.hot.accept()
}
