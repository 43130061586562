import { combineReducers } from 'redux'
import { Reducer as articles } from './articles'
import { Reducer as mostReadArticles } from './mostReadArticles'
import { Reducer as article } from './article'
import { Reducer as navPath } from 'ion-navchevron'
import { Reducer as app } from './app'
import { Reducer as staticPage } from './staticPage'
import { Reducer as feedback } from './feedback'
import { Reducer as subscribe } from './subscribe'
import { Reducer as search } from './search'
import { Reducer as magazineCover } from './magazineCover'
import { Reducer as editorsChoice } from './editorsChoice'
import { Reducer as weather } from './weather'
import { Reducer as user } from './user'

export default combineReducers({
  article,
  articles,
  mostReadArticles,
  navPath,
  app,
  staticPage,
  feedback,
  subscribe,
  search,
  magazineCover,
  editorsChoice,
  weather,
  user
})
