import axios from 'axios'
import { call, put, takeEvery } from 'redux-saga/effects'
import Cache from 'ion-cache'

export const FETCH_WEATHER = 'api/FETCH_WEATHER'
export const FETCH_WEATHER_SUCCESS = 'api/FETCH_WEATHER_SUCCESS'
export const FETCH_WEATHER_ERROR = 'api/FETCH_WEATHER_ERROR'

const WEATHER_SERVER_URL = typeof window !== 'undefined' ? '' : 'http://localhost:' + process.env.PORT
const cache = new Cache()
const key = '/weather/'

async function fetchWeatherAPI () {
  const result = await cache.get(key)
  if (result) {
    return result
  }
  return axios.get(WEATHER_SERVER_URL + '/data/weather/cape-town')
    .then(response => {
      return response.data[0]
    })
    .catch(err => {
      // console.error(err)
      throw err
    })
}

function * fetchWeatherSaga () {
  try {
    const res = yield call(fetchWeatherAPI)
    cache.set(key, res)
    yield put({ type: FETCH_WEATHER_SUCCESS, payload: res })
  } catch (e) {
    yield put({ type: FETCH_WEATHER_ERROR, payload: e.message })
  }
}

export function * watchFetchWeather () {
  yield takeEvery(FETCH_WEATHER, fetchWeatherSaga)
}

// Saga actions
export const fetchWeather = () => ({ type: FETCH_WEATHER, payload: {} })

export const Reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case FETCH_WEATHER:
      // Do not update the state
      return state
    case FETCH_WEATHER_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        hasFetched: false,
        hasError: true,
        message: payload
      })
    case FETCH_WEATHER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        hasFetched: true,
        hasError: false,
        weather: payload
      })
    default:
      return state
  }
}
