import axios from 'axios'
import { call, put, takeEvery } from 'redux-saga/effects'

export const SUBSCRIBE = 'api/SUBSCRIBE'
export const SUBSCRIBE_SUCCESS = 'api/SUBSCRIBE_SUCCESS'
export const SUBSCRIBE_ERROR = 'api/SUBSCRIBE_ERROR'
export const REINITIALIZE = 'api/REINITIALIZE'

export const UPDATE_DATA = 'api/UPDATE_DATA'

const SERVER_URL = typeof window !== 'undefined' ? '' : 'http://localhost:' + process.env.PORT

const subscribeAPI = (email) => {
  return axios.post(SERVER_URL + '/data/newsletter/subscribe', {
    email,
    lists: [process.env.RAZZLE_NEWSLETTER_SENDY_LIST],
    hp: '',
    error: ''
  })
    .then(response => response.data)
    .catch(err => {
      // console.error(err)
      throw err
    })
}

function * subscribeSaga ({ payload }) {
  try {
    yield call(subscribeAPI, payload)
    yield put({ type: SUBSCRIBE_SUCCESS })
  } catch (e) {
    console.error('ERROR while subscribing', e.message)
    yield put({ type: SUBSCRIBE_ERROR, payload: { message: e.message } })
  }
}

export function * watchSubscribe () {
  yield takeEvery(SUBSCRIBE, subscribeSaga)
}

// Saga actions
export const subscribeNewsletter = (email) => ({ type: SUBSCRIBE, payload: email })
export const updateForm = (email) => ({ type: UPDATE_DATA, payload: email })
export const reinitialize = () => ({ type: REINITIALIZE, payload: {} })

const defaultState = {
  email: ''
}

export const Reducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case REINITIALIZE:
      return Object.assign({}, defaultState)
    case UPDATE_DATA:
      return Object.assign({}, state, payload)
    case SUBSCRIBE:
      return Object.assign({}, state, {
        didInvalidate: false,
        isSubmiting: true,
        hasSubmited: false,
        hasError: false,
        error: null,
        data: payload
      })
    case SUBSCRIBE_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        error: payload.message,
        isSubmiting: false,
        hasSubmited: false,
        didInvalidate: true
      })
    case SUBSCRIBE_SUCCESS:
      return Object.assign({}, state, {
        isSubmiting: false,
        hasSubmited: true,
        didInvalidate: false
      })
    default:
      return state
  }
}
