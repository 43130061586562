import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Mobile } from 'ion-media'
import Ad from '../Ad'

import { FORMATS } from '../AdFormats'

const Sticky = [
  [[1024, 0], []],
  [[640, 0], [[320, 100], [320, 50], [300, 100], [300, 50], 'fluid']],
  [[0, 0], [[320, 100], [320, 50], [300, 100], [300, 50], 'fluid']]
]

const Footer = props => (
  <>
    <footer role='contentinfo' className={props.wallpaperRendered ? 'wallpaper-present' : ''}>
      <div className='wrapper'>
        <nav>
          <ul>
            <li>
              <Link to='/about-us'>About Daily Voice</Link>
            </li>
            <li>
              <Link to='/contact-us'>Contact Us</Link>
            </li>
            <li>
              <Link to='/terms-and-conditions'>Terms and Conditions</Link>
            </li>
            <li>
              <Link to='/privacy-policy'>Privacy Policy</Link>
            </li>
            <li>
              <Link to='/feedback'>Feedback</Link>
            </li>
            <li>
              <Link to='/complaints'>Complaints Procedure</Link>
            </li>
            <li>
              <Link to='/contact-us'>Advertise with us</Link>
            </li>
          </ul>
        </nav>
        <p>
          &copy; {new Date().toLocaleDateString('en-ZA', { year: 'numeric' })}{' '}
          Independent Media and affiliated companies. All rights reserved.
        </p>
        <p>Please visit the official Government information portal for Coronavirus by clicking <a href='https://sacoronavirus.co.za/' target='_blank' rel='noopener noreferrer nofollow'>HERE</a></p>
      </div>
    </footer>
    <Mobile>
      <Ad
        path={`/${process.env.RAZZLE_DFP_CODE}/${props.section || 'homepage'}`}
        targeting={{ 'mobile-leaderboard': 'sticky' }}
        slotId='ad-leaderboard-sticky'
        className='ad-leaderboard-sticky'
        mapping={Sticky}
        {...FORMATS.mobileLeaderboardSticky}
      />
    </Mobile>
  </>
)

export default Footer
